import imageUrlBuilder from '@sanity/image-url';
import groq from 'groq';
import { client as defaultClient, getClient } from 'services/sanity/client';
import {
  DownloadResourceByTag,
  HomePage,
  ImageBase,
  Page,
  ResourceTag,
  SiteSettings,
  TaggedDownloadResource,
  UpcomingEvent,
} from 'types/sanity';

/** Internal links could reference:
 *  - page
 *  - download resource
 */
const internalLink = groq`
_type == "internalLink" => {
  blank,
  "slug": @.reference->slug.current,
  "downloadResourceUrl": @.reference->resource.asset->url,
}`;

/** All resource tags */
const resourceTagsQuery = groq`
*[_type == "resourceTag"] {
  title,
  "slug": slug.current,
  description
}`;

/** Single resource tag
 * @param {string} title
 */
const resourceTagByTitleQuery = groq`
*[_type == "resourceTag" && title == $title] | order(_updatedAt desc)[0] {
  title,
  description
}`;

/** All download resources */
const downloadResourcesQuery = groq`
*[_type == "downloadResource"] | order(title asc) {
  title,
  description,
  "asset": resource.asset->,
  tags[] -> {
    title,
    description,
  }
}`;

/** All files for all tags */
const downloadResourcesByTagQuery = groq`
*[_type=="resourceTag"] {
  title,
  description,
  "files": *[_type=='downloadResource' && references(^._id)]| order(title asc) {
    title,
    description,
    "asset": resource.asset->
  }
}`;

/** All files for a single tag
 * @param {string} tag
 */
const downloadResourceByTagQuery = groq`
*[_type=="resourceTag" && (slug.current == $tag || title == $tag)][0] {
  title,
  "slug": slug.current,
  "files": *[_type=='downloadResource' && references(^._id)] | order(title asc) {
    title,
    description,
    "asset": resource.asset->
  }
}`;

const upcomingEventFields = groq`
_id,
title,
body[] {
  ...,
  markDefs[] {
    ...,
    ${internalLink}
  }
},
startDate,
endDate,
relatedRegions,
`;

/** An individual upcoming event
 * @param {string} id
 */
const upcomingEventById = groq`
*[_type=="upcomingEvent" && _id==$id][0] {
  ${upcomingEventFields}
}`;

/** All upcoming events
 * @param {string} date
 */
const upcomingEventsQuery = groq`
*[_type=="upcomingEvent" && $date >= startDate && $date <= endDate] | order(startDate desc) {
  ${upcomingEventFields}
}`;

/** Upcoming events for a region
 * @param {string} region
 * @param {string} date
 */
const upcomingEventsByRegionQuery = groq`
*[_type=="upcomingEvent" && $region in relatedRegions && $date >= startDate && $date <= endDate] | order(startDate desc) {
  ${upcomingEventFields}
}
`;

const homeQuery = groq`
*[_type == 'home'][0] {
  title,
  text,
  image,
}`;

const siteSettingsQuery = groq`
*[_type == 'siteSettings'][0] {
  title,
  description,
  image,
  "nav": {
    "header": headerNavigation[] {
      title,
      url,
      "url": "/" + internalLink->slug.current,
      blank,
    },
    "footer": footerNavigation[] {
      title,
      url,
      "url": "/" + internalLink->slug.current,
      blank,
    },
  }
}`;

const pageFields = groq`
title,
description,
slug,
body[] {
  ...,
  "asset": asset->,
  markDefs[] {
    ...,
    ${internalLink},
  }
}`;

const pageQuery = groq`
*[_type == "page"] {
  ${pageFields}
}`;

export async function getAllPages(preview?: boolean) {
  const pages = await getClient(preview).fetch<Page[]>(pageQuery);
  return { pages };
}

export async function getDocument(
  type?: string,
  slug?: string,
  preview?: boolean
) {
  const page = await getClient(preview).fetch<Page>(
    groq`*[_type == $type && slug.current == $slug] | order(_updatedAt desc)[0] {
      ${pageFields}
    }`,
    { type, slug }
  );

  return { page };
}

export function getPageBySlug(slug?: string, preview?: boolean) {
  return getDocument('page', slug, preview);
}

export async function getResourceTags(preview?: boolean) {
  const resourceTags =
    await getClient(preview).fetch<ResourceTag[]>(resourceTagsQuery);
  return { resourceTags };
}

export async function getResourceTagByTitle(title?: string, preview?: boolean) {
  const resourceTags = await getClient(preview).fetch<ResourceTag[]>(
    resourceTagByTitleQuery,
    { title }
  );
  return { resourceTags };
}

export async function getDownloadResources(preview?: boolean) {
  const downloadResources = await getClient(preview).fetch<
    TaggedDownloadResource[]
  >(downloadResourcesQuery);
  return downloadResources;
}

export async function getDownloadResourcesByTag(preview?: boolean) {
  const downloadResources = await getClient(preview).fetch<
    DownloadResourceByTag[]
  >(downloadResourcesByTagQuery);
  return downloadResources;
}

export async function getDownloadResourceByTag(
  tag?: string,
  preview?: boolean
) {
  const downloadResources = await getClient(
    preview
  ).fetch<DownloadResourceByTag>(downloadResourceByTagQuery, { tag });
  return downloadResources;
}

export async function getUpcomingEvents(date?: string, preview?: boolean) {
  const upcomingEvents = await getClient(preview).fetch<UpcomingEvent[]>(
    upcomingEventsQuery,
    { date }
  );
  return upcomingEvents;
}

export async function getUpcomingEventsByRegion(
  region?: string,
  date?: string,
  preview?: boolean
) {
  const upcomingEvents = await getClient(preview).fetch<UpcomingEvent[]>(
    upcomingEventsByRegionQuery,
    { region, date }
  );
  return upcomingEvents;
}

export async function getUpcomingEventById(id: string, preview?: boolean) {
  const upcomingEvent = await getClient(preview).fetch<UpcomingEvent>(
    upcomingEventById,
    { id }
  );
  return upcomingEvent;
}

export async function getSiteSettings(preview?: boolean) {
  const settings =
    await getClient(preview).fetch<SiteSettings>(siteSettingsQuery);
  return settings;
}

export async function getHomePage(preview?: boolean) {
  const home = await getClient(preview).fetch<HomePage>(homeQuery);
  return home;
}

// Get a pre-configured url-builder from your sanity client
const imageBuilder = imageUrlBuilder(defaultClient);

/**
 * Helper method to return the image builder that can be used to add additional
 * parameters.
 */
export function urlFor(source: ImageBase) {
  return imageBuilder.image(source);
}
