import sanityClient, { ClientConfig, SanityClient } from '@sanity/client';
import { isProd } from 'utils/helpers';

const getDataset = () => {
  const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET || 'production';
  return dataset;
};

const config: ClientConfig = {
  dataset: getDataset(),
  projectId: String(process.env.NEXT_PUBLIC_SANITY_PROJECT_ID), // you can find this in sanity.json
  useCdn: isProd(),
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
};

export const client = sanityClient(config);

const previewClient = sanityClient({
  ...config,
  // only want fresh data for previews which requires the token
  useCdn: false,
  withCredentials: true, // only users who are logged into Sanity Studio
});

export const getClient = (preview = false): SanityClient =>
  preview ? previewClient : client;
